import get from "lodash.get";
import ROLES from "../../../Roles.json";
import * as authService from "@/services/auth.service";
import { getUserDetails } from "@/services/user.service";
import authorizeManpower from "./authorizeManpower";
import authorizeCM from "./authorizeCM";
import authorizeIM from "./authorizeIM";
import authorizeFM from "./authorizeFM";
import authorizeOpsComm from "./authorizeOpsComm";
import authorizeTourist from "./authorizeTourist";
import authorizeShiftHandover from "./authorizeShiftHandover";
import { TOKEN_EXPIRED_DATE_THRESHOLD_IN_MINUTES } from "../../constants/values";
import { refreshToken } from "../../services/auth.service";
import { clearStorage } from "@/helpers/sessionStorage";
import { debounce } from "lodash";
import moment from "moment";

const state = () => ({
  userId: null,
  email: null,
  name: null,
  roleKey: null,
  roleName: null,
  token: null,
  tokenExpiredDate: null,
  personNo: null,
  station: null,
  zone: null,
  group: null,
  line: null,
});

const getters = {
  isAuthenticated(state) {
    return state.token !== null;
  },
  hasUserInfo(state) {
    return state.userId !== null;
  },
  isSysAdmin(state) {
    return state.roleKey === ROLES.SystemAdmin;
  },
  isOpsAdmin(state) {
    return state.roleKey === ROLES.OpsAdmin;
  },
  isMSO(state) {
    return state.roleKey === ROLES.ManagerStationOperation ||
      state.roleKey === ROLES.StationOperationManagement;
  },
  isSOM(state) {
    return state.roleKey === ROLES.ServiceOperationManager;
  },
  isOCC(state) {
    return state.roleKey === ROLES.OperationControlCentre;
  },
  isSMCR(state) {
    return state.roleKey === ROLES.StationManagerControlRoom;
  },
  isBelongToCCL(state) {
    return state.line.lineName === "Circle Line";
  },
  userId(state) {
    return state.userId;
  },
  token(state) {
    return state.token;
  },
  ...authorizeManpower,
  ...authorizeCM,
  ...authorizeFM,
  ...authorizeIM,
  ...authorizeOpsComm,
  ...authorizeTourist,
  ...authorizeShiftHandover,
  userStationId: (state) => get(state, "station.stationId"),
  abbreviation: (state) => get(state, "station.abbreviation"),
  userZoneId: (state) => get(state, "zone.zoneId"),
  userGroupId: (state) => get(state, "group.groupId"),
  userLineId: (state) => get(state, "line.lineId"),
  userName: (state) => get(state, "name"),
  personNo: (state) => get(state, "personNo"),
  userRole: (state) => get(state, "roleName"),
  userLineName: (state) => get(state, "line.lineName"),
  userInitLineName: (state) => get(state, "line.initlineName"),
  userStationName: (state) => get(state, "station.stationName"),
  userZoneName: (state) => get(state, 'zone.zoneName'),
  userGroupName: (state) => get(state, 'group.groupName'),
  userLineOption: (state) =>
    state.line === null
      ? null
      : {
        label: get(state, "line.lineName"),
        value: get(state, "line.lineId"),
      },
  userStationOption: (state) =>
    state.station === null
      ? null
      : {
        label: get(state, "station.stationName"),
        value: get(state, "station.stationId"),
      },
  canSelectLine(state) {
    return (
      state.roleKey === ROLES.FSOAdministrativeOfficer ||
      state.roleKey === ROLES.FSOManager ||
      state.roleKey === ROLES.SystemAdmin
    );
  },
};

const mutations = {
  /**
   * Pick up token from persisten storage or from API
   * @param {*} token
   */
  setToken(state, token) {
    state.token = token;
  },
  setTokenExpiredDate(state, tokenExpiredDate) {
    state.tokenExpiredDate = tokenExpiredDate;
  },
  setRefreshingToken(state, value) {
    state.setRefreshingToken = !!value;
  },
  setUserLineId(state, userId) {
    state["line.lineId"] = userId;
  },
  setUserLineName(state, lineName) {
    state["line.lineName"] = lineName;
  },
  setInitUserLineName(state, initlineName) {
    state["line.initlineName"] = initlineName;
  },
  /**
   * Set user info from API data
   * @param {*} userInfo
   */
  setUserInfoFromLogin(state, userInfo) {
    // eslint-disable-next-line no-debugger
    state.userId = userInfo.officerId;
    state.email = userInfo.email;
    state.name = userInfo.name;
    state.line = {
      initlineName: userInfo.lineName,
      lineName: userInfo.lineName,
      lineId: userInfo.lineId,
    };
    state.personNo = userInfo.personNo;
    var roleKeys = Object.keys(get(userInfo, "roles", {}));
    if (roleKeys.length > 0) {
      state.roleKey = roleKeys[0];
      state.roleName = userInfo.roles[roleKeys[0]];
    }

    // -----------监测token
    const tokenObj = window.localStorage.getItem("tokenHistory");
    const deviceId = window.localStorage.getItem("asomsDeviceId");
    const tokenData = tokenObj ? JSON.parse(tokenObj) : [];
    tokenData.push({
      txt: '查询用户 - 从用户信息赋值token',
      enTxt: 'Query User - Assign Token from User Information',
      date: new Date().toLocaleString(),
      url: window.location.href.split('#')[1],
      asomsDeviceId: deviceId || null,
      token: userInfo.token,
    })
    window.localStorage.setItem("tokenHistory", JSON.stringify(tokenData));
    // -----------监测token


    state.token = userInfo.token;
    state.tokenExpiredDate = userInfo.tokenExpiredDate;
  },
  /**
   * Set user info from API data
   * @param {*} userInfo
   */
  setUserInfoFromRefresh(state, userInfo) {
    state.personNo = userInfo.personNo;
    state.line = userInfo.line;
    state.station = userInfo.station;
    state.zone = userInfo.zone;
    state.group = userInfo.group;
  },
  /**
   * Reset auth data
   */
  clearUserInfo(state) {
    state.userId = null;
    state.userLineId = null;
    state.userLineName = null;
    state.userInitLineName = null;
    state.email = null;
    state.name = null;
    state.roleKey = null;
    state.roleName = null;
    state.token = null;
    state.tokenExpiredDate = null;
    state.personNo = null;
    state.station = null;
    state.zone = null;
    state.group = null;
    state.line = null;
  },
};

const actions = {
  refreshUserInfo: async function ({ commit, state }) {
    if (!state.token) return;
    const result = await getUserDetails(state.userId);
    if (result.success) {
      commit("setUserInfoFromRefresh", get(result, "payload.data", {}));
      return true;
    } else {
      return result.payload;
    }
  },
  setLineName(content, payload) {
    content.commit('setUserLineName', payload)
  },
  setLineId(content, payload) {
    content.commit('setUserLineId', payload)
  },
  refreshTokenNearExpiryDate: debounce(async function ({ commit, state, getters }) {
    const m = moment.utc(state.tokenExpiredDate);
    const isPass = m.isValid() && m.diff(new Date(), 'minutes') <= TOKEN_EXPIRED_DATE_THRESHOLD_IN_MINUTES;


    // state.token
    // -----------监测token
    const tokenObj = window.localStorage.getItem("tokenHistory");
    const deviceId = window.localStorage.getItem("asomsDeviceId");
    const tokenData = tokenObj ? JSON.parse(tokenObj) : [];
    tokenData.push({
      txt: '查询token有效时间 - 是否小于15分钟',
      enTxt: 'Check if the token validity time is less than 15 minutes',
      date: new Date().toLocaleString(),
      url: window.location.href.split('#')[1],
      asomsDeviceId: deviceId || null,
      oldToken: state.token,
    })
    window.localStorage.setItem("tokenHistory", JSON.stringify(tokenData));
    // -----------监测token

    const shouldRefreshToken = getters['isAuthenticated'] && isPass;
    if (!shouldRefreshToken) return;
    commit("setRefreshingToken", true);
    const result = await refreshToken();
    commit("setRefreshingToken", false);
    const isResultSuccess = result && result.success && result.payload && result.payload && result.payload.token;

    // -----------监测token
    const tokenObj2 = window.localStorage.getItem("tokenHistory");
    const deviceId2 = window.localStorage.getItem("asomsDeviceId");
    const tokenData2 = tokenObj2 ? JSON.parse(tokenObj2) : [];
    // -----------监测token


    if (isResultSuccess) {
      // -----------监测token
      tokenData2.push({
        txt: '刷新token - 重新获取成功',
        enTxt: 'refresh token - Refresh Success',
        date: new Date().toLocaleString(),
        url: window.location.href.split('#')[1],
        asomsDeviceId: deviceId2 || null,
        newToken: result.payload.token,
      })
      window.localStorage.setItem("tokenHistory", JSON.stringify(tokenData2));
      // -----------监测token

      commit("setUserInfoFromLogin", get(result, "payload", {}));
    } else {
      // -----------监测token
      tokenData2.push({
        txt: '刷新token - 重新获取失败',
        enTxt: 'refresh token - refresh failed',
        date: new Date().toLocaleString(),
        url: window.location.href.split('#')[1],
        asomsDeviceId: deviceId2 || null,
        token: null,
      })
      window.localStorage.setItem("tokenHistory", JSON.stringify(tokenData2));
      // -----------监测token
    }
  }, 3000),

  login: async function ({ commit }, loginData) {

    // -----------监测token
    const tokenObj = window.localStorage.getItem("tokenHistory");
    const deviceId = window.localStorage.getItem("asomsDeviceId");
    let result = null;
    result = await authService.login({ ...loginData, message: tokenObj });
    if (result.success) {
      window.localStorage.setItem("tokenHistory", JSON.stringify([{
        txt: '登录了 - 重新记录token',
        enTxt: 'Login - Re record token',
        date: new Date().toLocaleString(),
        url: window.location.href.split('#')[1],
        asomsDeviceId: deviceId || null,
        token: null,
      }]));
      // -----------监测token

      commit('setUserLineId', result.payload.lineId)
      commit('setUserLineName', result.payload.lineName)
      commit('setInitUserLineName', result.payload.lineName)
      commit("setUserInfoFromLogin", result.payload);
    }

    clearStorage();
    return result;
  },
  logout: async function ({ commit }) {

    // -----------监测token
    const tokenObj = window.localStorage.getItem("tokenHistory");
    const deviceId = window.localStorage.getItem("asomsDeviceId");
    const tokenData = tokenObj ? JSON.parse(tokenObj) : [];
    tokenData.push({
      txt: '退出登录 - 清空token',
      enTxt: 'Logout - Clear Token',
      date: new Date().toLocaleString(),
      url: window.location.href.split('#')[1],
      asomsDeviceId: deviceId || null,
      token: null,
    })
    window.localStorage.setItem("tokenHistory", JSON.stringify(tokenData));
    // -----------监测token

    const result = await authService.logout();
    if (result.success) {
      commit("clearUserInfo");
    }
    return result;
  },
  forceLogout: ({ commit }) => {

    // -----------监测token
    const tokenObj = window.localStorage.getItem("tokenHistory");
    const deviceId = window.localStorage.getItem("asomsDeviceId");
    const tokenData = tokenObj ? JSON.parse(tokenObj) : [];
    tokenData.push({
      txt: '清空用户信息 - 清空token',
      enTxt: 'Clear User Info - Clear Token',
      date: new Date().toLocaleString(),
      url: window.location.href.split('#')[1],
      asomsDeviceId: deviceId || null,
      token: null,
    })
    window.localStorage.setItem("tokenHistory", JSON.stringify(tokenData));
    // -----------监测token

    commit("clearUserInfo");
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
